/* Define the slide-down animation with a delay */
.slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-down-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition:
    transform 500ms ease-out 300ms,
    opacity 500ms ease-out 300ms;
  /* Add a 300ms delay */
}

.slide-down-exit {
  transform: translateY(0);
  opacity: 1;
}

.slide-down-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition:
    transform 500ms ease-in,
    opacity 500ms ease-in;
  /* No delay on exit */
}
